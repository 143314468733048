module.exports = {
  siteTitle: "Digital Colors | ゲームの凄いところまとめサイト", // <title>
  shortSiteTitle: "Digital Colors", // <title> ending for posts and pages
  siteDescription: "ゲーム機の具体的な性能や「実はここが凄い！」という普通の人は知らない情報をまとめています。ファミコン、ニンテンドーDS世代から、PlayStation 5やNintendo Switchなどこれからの世代まで分かりやすく解説します。",
  siteUrl: "https://www.d-colors.net",
  // pathPrefix: "",
  siteImage: "preview.jpg",
  siteLanguage: "ja",

  /* author */
  authorName: "すくね",
  authorTwitterAccount: "",
  authorGithub: "https://github.com/baobabkoodaa",
  authorStackoverflow: "https://stackexchange.com/users/5678032/atte-juvonen?tab=accounts",
  authorLinkedin: "https://linkedin.com/in/atte-juvonen-86b886113",
  authorCodeforces: "https://codeforces.com/profile/baobab",
  authorYoutube: "https://www.youtube.com/channel/UCfHqfHoSYL0V_fXA_JQkeLw?view_as=subscriber",
  authorUnsplash: "https://unsplash.com/@baobabkoodaa",
  authorYCombinator: "https://news.ycombinator.com/user?id=baobabKoodaa",

  /* header text */
  headerTitle: "DigitalColors",
  headerSubTitle: "ゲームの凄いところまとめサイト",

  /* manifest.json */
  manifestName: "DigitalColors - ゲームの凄いところまとめサイト",
  manifestShortName: "DigitalColors", // max 12 characters
  manifestStartUrl: "/",
  manifestBackgroundColor: "white",
  manifestThemeColor: "#666",
  manifestDisplay: "standalone",

  // gravatar
  // Use your Gravatar image. If empty then will use src/images/jpg/avatar.jpg
  // Replace your email adress with md5-code.
  // Example https://www.gravatar.com/avatar/g.strainovic@gmail.com ->
  // gravatarImgMd5: "https://www.gravatar.com/avatar/1db853e4df386e8f699e4b35505dd8c6",
  gravatarImgMd5: "",
};
